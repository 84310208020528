import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UpdaterComponent } from './updater.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
@NgModule({
  declarations: [UpdaterComponent],
  exports: [UpdaterComponent],
  imports: [
    BrowserModule,
    MatToolbarModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule,
  ],
  providers: [],
})
export class UpdaterModule {}
