import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Observable abstraction over ngOnDestroy to use with takeUntil
 */
@Injectable()
export class DestroyService extends Subject<void> implements OnDestroy {
  ngOnDestroy() {
    this.next();
    this.complete();
  }
}

/**
 *Использование
 *@Component({
 *    selector: 'hml-component',
 *    templateUrl: './component.component.html',
 *    styleUrls: [ './component.component.scss' ],
 *    providers:[DestroyService], -- обязательно указывать сервис в провайдерc данного компонента
 *})
 *export class Component implements OnInit {
 *    @Self - обязательно, чтобы убедиться, что DestroyService запровайдили в компонете
 *    constructor(@Self() private readonly destroy$: DestroyService) {}
 *
 *    ngOnInit(){
 *        observable$.pipe(takeUntil(this.destroy$)).subscribe();
 *    }
 *}
 *
 *так тоже можно
 *export class Component implements OnInit, OnDestroy {
 *    constructor(@Self private readonly destroy$: DestroyService) {}
 *
 *    ngOnInit(){
 *        observable$.pipe(takeUntil(destroy$)).subscribe();
 *    }
 *
 *    ngOnDestroy(){ - хук компонента тоже работает
 *        console.log('onDestroy');
 *    }
 *}
 */
